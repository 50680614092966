// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

/**
 * 在主框架之外显示
 */
const page = [
  {
    path: '/',
    name: 'MobileIndex',
    redirect: '/mobile/home',
  },
  {
    path: '/mobile/home',
    name: 'MobileHome',
    component: _import('mobile/home/index'),
  },
  {
    path: '/mobile/creator-program',
    name: 'MobileCreatorprogram',
    component: _import('mobile/creator-program/index'),
    meta: {
      title: '创作者计划',
    },
  },
  {
    path: '/mobile/growth-academy',
    name: 'MobileGrowthAcademy',
    component: _import('mobile/growth-academy/index'),
    meta: {
      title: '成长学院',
    },
  },
  {
    path: '/mobile/growth-academy/detail',
    name: 'MobileGrowthAcademyDetail',
    component: _import('mobile/growth-academy/detail'),
    meta: {
      title: '成长学院',
    },
  },
  {
    path: '/mobile/r-space',
    name: 'MobileRspace',
    component: _import('mobile/r-space/index'),
    meta: {
      title: 'R空间',
    },
  },
  {
    path: '/argument',
    name: 'MobileArgument',
    component: _import('home/argument'),
    meta: {
      title: '用户服务协议',
    },
  },
  {
    path: '/sdkUse',
    name: 'MobileSdkUse',
    component: _import('home/sdkUse'),
    meta: {
      title: '第三方信息分享清单',
    },
  },
  {
    path: '/userInfoList',
    name: 'MobileUserInfoList',
    component: _import('home/userInfoList'),
    meta: {
      title: '用户信息使用清单',
    },
  },
  {
    path: '/accountLogout',
    name: 'AccountLogout',
    component: _import('home/accountLogout'),
    meta: {
      title: '账号注销协议',
    },
  },
  {
    path: '/landing',
    name: 'MobileLanding',
    component: _import('mobile/landing/landingPage'),
    meta: {
      title: 'R空间',
    },
  },
  {
    path: '/share/chat',
    name: 'ShareChat',
    component: _import('mobile/share/chat'),
    meta: {
      title: 'R空间',
    },
  },
  {
    path: '/share/content',
    name: 'ShareContent',
    component: _import('mobile/share/content'),
    meta: {
      title: 'R空间',
    },
  },
  {
    path: '/benefit',
    name: 'Benefit',
    component: _import('mobile/benefit'),
    meta: {
      title: '社群福利-R空间',
    },
  },

];

// 重新组织后导出
export default [...page];
