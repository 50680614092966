import axios from 'axios';
import util from '@/libs/util.js';
import { Message } from 'element-ui';
import { httpCodeMap, SINGLE_TOKEN_KEY, SINGLE_UUID_KEY } from '@/maps/common';
import store from '@/store/index';
import router from '@/router'; // 导入 Vue Router 实例

const getBaseURL = () => {
  let url = '/';
  if (process.env.NODE_ENV === 'development') {
    url = process.env.VUE_APP_API_MODULE;
  } else {
    url = process.env.VUE_APP_SERVER + process.env.VUE_APP_API_MODULE;
  }
  console.log(url);
  return url;
};

const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const mobileKeywords =
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;

  return mobileKeywords.test(userAgent);
};

const service = axios.create({
  baseURL: getBaseURL(), // api 的 base_url  process.env.BASE_API
  timeout: 30000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    // 这里只能用util.cookies.get的方式获取token
    const token = util.cookies.get(SINGLE_TOKEN_KEY);
    if (token || config.url !== '/user/send/code') {
      // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
      config.headers.Authorization = token;
    }
    config.headers.appId = store.getters['system/getAppID'];
    return config;
  },
  error => {
    // Do something with request error
    // for debug
    Promise.reject(error);
  },
);
service.interceptors.response.use(
  response => {
    /** 这里的逻辑是确保只有正确的返回才会走到then里面，所有错误的结果都在catch中处理 */
    const { code, msg, message } = response.data;
    // 200 正常请求(只要code===200，说明接口数据没有异常，否则找后端协调)
    if (+code === httpCodeMap.success) {
      return response.data;
    } else {
      // 接口内部错误都统一在这里提示错误
      if ((msg || message) && code !== 4010) {
        Message({
          message: msg || message,
          type: 'error',
          duration: 2 * 1000,
        });
      }
      if (code === '401') {
        if (isMobile()) {
          router.replace('/mobile/home');
        } else {
          router.replace('/home');
        }
        util.clearUser();
      }
      return Promise.reject(response.data);
    }
  },
  error => {
    if (error?.response) {
      switch (error.response.status) {
        case 400:
          error.message = '错误请求';
          break;
        case 401:
          error.message = '未授权，请重新登录';
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = '请求错误,未找到该资源';
          break;
        case 405:
          error.message = '请求方法未允许';
          break;
        case 408:
          error.message = '请求超时';
          break;
        case 500:
          error.message = '服务器端出错';
          break;
        case 501:
          error.message = '网络未实现';
          break;
        case 502:
          error.message = '网络错误';
          break;
        case 503:
          error.message = '服务不可用';
          break;
        case 504:
          error.message = '网络超时';
          break;
        case 505:
          error.message = 'http版本不支持该请求';
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      error.message = '连接到服务器失败';
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 2 * 1000,
    });
    if (error.response.status === 401) {
      util.cookies.remove(SINGLE_TOKEN_KEY);
      util.cookies.remove(SINGLE_UUID_KEY);
      store.dispatch('user/setToken', null);
      store.dispatch('user/setUserInfo', null);
      store.dispatch('system/setIsLogin', false);
    }
    return Promise.reject(error);
  },
);

export default service;
